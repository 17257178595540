.homeSection {
    padding-top: 75px;
    height: 90vh;
}
.homeSection .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.homeSection .container .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.homeSection .container .right {
    /* https://ahmedkira.com/images/about.jpg */
    /* background-image: url("https://ahmedkira.com/images/about.jpg"); */
    width: 50%;
}
.homeSection .container .right img {
    /* width: 50%; */
    /* height: 90%; */
    max-height: 90vh;
    height: fit-content;
    object-fit: cover;
    border-radius: 10px;
}