.myProjectsSection {
    display: flex;
    justify-content: center;
}
.myProjectsSection .container h2{
    font-size: 50px;
    font-weight: 900;
    position: relative;
    text-align: center;
    width: 60vw;

}
.myProjectsSection h2::after{
    content: 'My Projects';
    position: absolute;
    width: 100%;
    color: #ffffff1a;
    font-size: 7vw;
    font-weight: 900;
    top: 0;
    left: 0;
    z-index: -1;
}
.myProjectsSection .container p.quote{
    font-size: 20px;
    font-weight: 300;
    margin-top: 50px;
    margin-bottom: 50px;
}