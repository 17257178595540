.aboutSection {
    display: flex;
    justify-content: center;
}
.aboutSection .container {
    margin: 100px 9vw 0 9vw;
}
.aboutSection h2{
    font-size: 50px;
    font-weight: 700;
    position: relative;
}
.aboutSection h2::after{
    content: 'About';
    position: absolute;
    width: 100%;
    color: #ffffff1a;
    font-size: 7vw;
    font-weight: 900;
    top: 0;
    left: -15px;
    z-index: -1;
}
.aboutSection .info .line{
    display: flex;
    align-items: center;
    flex-direction: row;
}
.aboutSection .info .line h4 {
    font-size: 18px;
    font-weight: 700;
    margin-right: 20px;
    width: 140px;

}
.about-section-left, .about-section-right {
    min-height: 400px;
}
.about-section-right {
    width: 55%;
}
.about-section-left {
    width: 45%;
}
.about-section-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.about-section-right span{
    color: #999999;
    /* font-size: 14px; */
    font-weight: 400;
    padding-inline-start: 5px;
}
.about-section-right p {
    color: #999999;
    line-height: 1.7;
}
.aboutSection .projectCount{
    color: #fff;
    font-size: 20px;
    font-weight: 400;
}
.aboutSection .projectCount .no{
    color: #ffbd39;
    font-size: 20px;
    font-weight: 600;
}
.aboutSection .download-cv-btn {
    background: #ffbd39;
    border: 1px solid #ffbd39;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 16px;
    border-radius: 40px;
    width: 148px;
    text-align: center;

}
@media screen and (max-width: 768px) {
    .about-section-left {
        display: none;
    }
    .about-section-right {
        width: 80vw;
    }
}
