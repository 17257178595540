.mySkillsSection {
    display: flex;
    justify-content: center;
}
.mySkillsSection .container{
    margin: 100px 9vw 0 9vw;
    text-align: center;
}
.mySkillsSection .container h2{
    font-size: 50px;
    font-weight: 900;
    position: relative;

}
.mySkillsSection h2::after{
    content: 'My Skills';
    position: absolute;
    width: 100%;
    color: #ffffff1a;
    font-size: 7vw;
    font-weight: 900;
    top: 0;
    left: 0;
    z-index: -1;
}
.mySkillsSection .container p.quote{
    font-size: 20px;
    font-weight: 300;
    margin-top: 50px;
    margin-bottom: 50px;
}
.mySkillsSection .container .skills{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.mySkillsSection .container .skills .skill{
    width: 200px;
    height: 200px;
    margin: 10px;
    border-radius: 50%;
    background-color: #ffffff1a;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    transition: all 0.3s ease-in-out;
}
.mySkillsSection .container .skills .skillCard:hover{
    /* transform: scale(1.1); */
}
.mySkillsSection .container .skills .skillCard {
    width: 200px;
    height: 200px;
    margin: 10px;
    border-radius: 50%;
    background-color: #ffffff1a;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    transition: all 0.3s ease-in-out;
}
.mySkillsSection .container .skills .skillCard .skillCard-title{
    font-size: 20px;
    font-weight: 900;
    color: #fff;
}
.mySkillsSection .container .skills .skillCard .skillCard-percent{
    font-size: 20px;
    font-weight: 300;
    color: #fff;

}