.resumeCard {
    background-color: #ffffff1a;
    padding: 30px;
    border-radius: 5px;
    margin: 15px;
    width: 500px;
    /* display: flex; */
    text-align: left;
    line-height: 1.8;

}
.resumeCard .date {
    color: #ffbd39;
    font-size: 26px;
    font-weight: 900;
    line-height: 1.8;

}
.resumeCard .name {
    font-size: 26px;
}
.resumeCard .company{
    color: #999999;
    line-height: 1.5;

}
@media screen and (max-width: 768px) {
    .resumeCard {
        width: 80vw;
    }
}
