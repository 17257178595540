.viewFullCV {
    margin-top: 70px;
    margin-bottom: 90px;
}

.viewFullCV .download-cv-btn {
    background: #ffbd39;
    border: 1px solid #ffbd39;
    color: #000;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 24px 48px;
    border-radius: 40px;
    width: 228px;
    text-align: center;
}