.resumeSection {
    display: flex;
    justify-content: center;
    
}
.resumeSection .container{
    margin: 100px 9vw 0 9vw;
    text-align: center;
}
.resumeSection .container h2{
    font-size: 50px;
    font-weight: 900;
    position: relative;

}
.resumeSection h2::after{
    content: 'Resume';
    position: absolute;
    width: 100%;
    color: #ffffff1a;
    font-size: 7vw;
    font-weight: 900;
    top: 0;
    left: 0;
    z-index: -1;
}
.resumeSection .container p.quote{
    font-size: 20px;
    font-weight: 300;
    margin-top: 50px;
    margin-bottom: 50px;
}
