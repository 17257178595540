header.header {
    height: 75px;
    background-color: #000;
    position: sticky;
    top: 0;
    z-index: 100;
}
header.header .logo {
    color: #fff;
    font-size: 24px;
    font-weight: 900;
    /* font-weight: bold; */
    padding: 20px;
}
header.header .container {
    padding-left: 7vw;
    padding-right: 8vw;
    height: 100%;
}
header.header .nav .link{
    position: relative;
    padding: 0 0 5px 0;
}
header.header .nav .link.active {
    color: #ffbd39;
}
header.header .nav .link.active::before, header.header .nav .link:hover::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #ffbd39;
    bottom: 0;
    left: 0;
}
